import ReactDOM from 'react-dom/client'

import { getQueryClient } from '../shared/components/QueryClientContext'
import { parseJSONFromScript } from '../shared/parseJSONFromScript'
import Canvas from './components/Canvas'
import { MAP_SUBTYPE_DAY_PERF } from './constants/constants'
import { SettingsSmall, SettingsSmallDark, SettingsSmallGeo, SettingsSmallGeoDark } from './constants/settings'
import Treemap from './treemap'
import { MapDataRoot, MapTypeId, PerfData } from './types'
import * as mapUtils from './utils'

const CANVAS_WIDTH = 400
const CANVAS_HEIGHT_GEO = 235
const CANVAS_HEIGHT = 300
const CANVAS_HEIGHT_NEW = 368

async function renderTreemaps(props: { perfDataSec: PerfData; perfDataGeo: PerfData; cacheBuster: number }) {
  const queryClient = getQueryClient({ defaultOptions: { queries: { useErrorBoundary: false } } })

  let precomputedData
  try {
    precomputedData = await queryClient.fetchQuery({
      queryKey: ['mapBaseData', 'homepage'],
      queryFn: mapUtils.getIndexMapData as unknown as () => Promise<{ sec: MapDataRoot; geo: MapDataRoot }>,
    })
  } catch {}

  if (!precomputedData) return

  if (props.perfDataSec) {
    const subtype = MAP_SUBTYPE_DAY_PERF
    const scale = { id: subtype.scaleId, name: subtype.label, tooltip: subtype.tooltip }
    const initData = mapUtils.getMapData(precomputedData.sec, props.perfDataSec)
    const treemap = new Treemap({
      data: initData,
      scale,
      width: CANVAS_WIDTH,
      height: FinvizSettings.hasRedesignEnabled ? CANVAS_HEIGHT_NEW : CANVAS_HEIGHT,
      countIndustryPerf: false,
      countSectorPerf: true,
      settings: FinvizSettings.hasDarkTheme ? SettingsSmallDark : SettingsSmall,
      type: MapTypeId.Sector,
      subtype: subtype.value,
      isSmall: true,
      zoomLevels: [1],
      version: props.perfDataSec.version,
      dataHash: props.perfDataSec.hash,
    })

    window.FinvizIndexSecMap = treemap

    const rootEl = document.getElementById('treemap-small')
    const root = ReactDOM.createRoot(rootEl!)
    root.render(<Canvas treemap={treemap} hover={false} legend={false} zoom={false} alt="S&P 500 Map" />)
  }

  if (props.perfDataGeo) {
    const subtype = MAP_SUBTYPE_DAY_PERF
    const scale = { id: subtype.scaleId, name: subtype.label, tooltip: subtype.tooltip }
    const initData = mapUtils.getMapData(precomputedData.geo, props.perfDataGeo)
    const treemap = new Treemap({
      data: initData,
      scale,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT_GEO,
      countIndustryPerf: true,
      countSectorPerf: false,
      settings: FinvizSettings.hasDarkTheme ? SettingsSmallGeoDark : SettingsSmallGeo,
      type: MapTypeId.World,
      subtype: subtype.value,
      isSmall: true,
      zoomLevels: [1],
      version: props.perfDataGeo.version,
      dataHash: props.perfDataGeo.hash,
    })

    window.FinvizIndexGeoMap = treemap

    const rootEl = document.getElementById('treemap-small-geo')
    const root = ReactDOM.createRoot(rootEl!)
    root.render(<Canvas treemap={treemap} hover={false} legend={false} zoom={false} alt="World Map" />)
  }
}

if (document.getElementById('map-data')) {
  const mapProps = parseJSONFromScript<{ perfDataSec: PerfData; perfDataGeo: PerfData; cacheBuster: number }>(
    'map-data'
  )
  if (mapProps) void renderTreemaps(mapProps)
}
